import BaseApi from "./BaseApi";

const SERVICES_URL = process.env.REACT_APP_SERVICES_URL + "/api";
const AUDIT_URL = process.env.REACT_APP_AUDIT_URL + "/api";
const BOC_S3_ENV = process.env.REACT_APP_BOC_S3_ENV;

class ZeApi extends BaseApi {
  constructor(auditS3Api) {
    super();
    this.auditS3Api = auditS3Api;
  }

  getAuditConfiguration = gamePk => {
    const url = SERVICES_URL + "/auditConfig?gamePk=" + gamePk;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  saveAuditConfiguration = config => {
    const url = SERVICES_URL + "/auditConfig";
    return this.axiosWrapper?.post(url, config).then(response => response.data);
  };

  // Disputes Screen

  getDisputes = disputesUrl => {
    const url = SERVICES_URL + disputesUrl;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  getDisputesCount = disputesUrl => {
    const url = SERVICES_URL + disputesUrl;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  getTeams = () => {
    const url = SERVICES_URL + "/lookup/team";
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  getUmpires = season => {
    const url = SERVICES_URL + "/lookup/umpire?season=" + season;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  getUmpiresWithDisputes = season => {
    const url = SERVICES_URL + "/lookup/umpire/disputes?season=" + season;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  resolveDisputes = disputes => {
    let url = SERVICES_URL + "/dispute/resolve";
    return this.axiosWrapper?.post(url, disputes).then(response => response.data);
  };

  saveDispute = dispute => {
    let url = SERVICES_URL + "/dispute";
    return this.axiosWrapper?.post(url, dispute).then(response => response.data);
  };

  saveDisputeComment = (comment, selectedDispute) => {
    let url = SERVICES_URL + "/dispute/comment/" + selectedDispute.disputeId;
    return this.axiosWrapper?.post(url, comment).then(response => response.data);
  };

  unresolveDisputes = disputes => {
    let url = SERVICES_URL + "/dispute/unresolve";
    return this.axiosWrapper?.post(url, disputes).then(response => response.data);
  };

  //Schedule Screen

  getGames = gamePk => {
    const url = SERVICES_URL + "/schedule/gamePk/" + gamePk;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  getSchedule = (date, getGameStatus) => {
    const url = SERVICES_URL + "/schedule/" + date + "?getGameStatus=" + getGameStatus;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  getUmpireSchedule = (season, umpires, sortCol, asc) => {
    let url = SERVICES_URL + "/schedule/umpire/" + season + "?sortedCol=" + sortCol + "&asc=" + asc;
    if (umpires) {
      umpires.split(",").forEach(u => (url += "&umpireIds=" + u));
    }
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  initializeGame = gamePk => {
    const url = SERVICES_URL + "/game/" + gamePk;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  releaseGame = (gamePk, umpireId, override) => {
    let url = SERVICES_URL + "/game/release/" + gamePk + "/" + umpireId;
    if (override) {
      url += "?override=" + override;
    }
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  moveBackToInitialized = gamePk => {
    const url = SERVICES_URL + "/game/reinitialize/" + gamePk;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  analyzeAllPitches = gamePk => {
    const url = SERVICES_URL + "/game/analysis/" + gamePk;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  analyzePitches = (gamePk, pitchList) => {
    const url = SERVICES_URL + "/game/analysis/" + gamePk + "/pitches";
    return this.axiosWrapper?.post(url, pitchList).then(response => response.data);
  };

  analyzePitchesById = (gamePk, pitchList) => {
    const url = SERVICES_URL + "/game/analysis/" + gamePk + "/pitches/id";
    return this.axiosWrapper?.post(url, pitchList).then(response => response.data);
  };

  unreleaseGame = (gamePk, umpireId) => {
    const url = SERVICES_URL + "/game/unrelease/" + gamePk + "/" + umpireId;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  updateUmpireStances = (game, umpireId) => {
    const url = SERVICES_URL + "/game/umpireStances/" + game.gamePk + "/" + umpireId;
    return this.axiosWrapper?.post(url, game.umpireStances).then(response => response.data);
  };

  //Audit Screen

  applyCalibration = (gamePk, playGuid, applyCalibrationObj) => {
    const url = `${AUDIT_URL}/v0.1/${gamePk}/${playGuid}/apply_calibration`;
    return this.axiosWrapper?.post(url, applyCalibrationObj).then(response => response.data);
  };

  cutAllFrames = gamePk => {
    const url = `${SERVICES_URL}/clip/allFrames/${gamePk}`;
    return this.axiosWrapper?.get(url, gamePk).then(response => response.data);
  };

  cutFrames = cutFramesObj => {
    const url = SERVICES_URL + "/clip/frames";
    return this.axiosWrapper?.post(url, cutFramesObj).then(response => response.data);
  };

  generateImages = (gamePk, generateImagesObj) => {
    const url = `${AUDIT_URL}/v0.1/games/${gamePk}/strike_zone_images`;
    return this.axiosWrapper?.post(url, generateImagesObj).then(response => response.data);
  };

  getPitchList = gamePk => {
    const url = SERVICES_URL + "/audit/" + gamePk;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  getStrikeZone = (gamePk, playGuid, source) => {
    const url = `${AUDIT_URL}/v0.1/${gamePk}/${playGuid}/strike_zone`;
    if (source) {
      return this.axiosWrapper
        ?.get(url, {
          cancelToken: source.token
        })
        .then(response => response.data);
    } else {
      return this.axiosWrapper?.get(url).then(response => response.data);
    }
  };

  refreshPitch = (gamePk, playId) => {
    const url = SERVICES_URL + "/audit/" + gamePk + "/" + playId;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  updateStrikeZone = (saveObj, gamePk, playGuid) => {
    const url = `${AUDIT_URL}/v0.1/${gamePk}/${playGuid}/strike_zone`;
    return this.axiosWrapper?.post(url, saveObj).then(response => response.data);
  };

  getLegacyCalibration = (gamePk, playGuid, source) => {
    const url = `${AUDIT_URL}/v0.1/${gamePk}/${playGuid}/calibration`;
    return this.axiosWrapper
      ?.get(url, {
        cancelToken: source.token
      })
      .then(response => response.data);
  };

  getFieldOutline = (gamePk, playGuid, source) => {
    const url = `${AUDIT_URL}/v0.1/${gamePk}/${playGuid}/field_outline`;
    return this.axiosWrapper
      ?.get(url, {
        cancelToken: source.token
      })
      .then(response => response.data);
  };

  getFieldOutlineFromS3 = (gamePk, playGuid) => {
    let path = BOC_S3_ENV + "/" + gamePk + "/" + playGuid + "/cf-fieldOutline.png";
    let bucket = "ze-audit-graphics";
    return this.auditS3Api.getImage(bucket, path);
  };

  updateFieldOutline = (saveObj, gamePk, playGuid) => {
    const url = `${AUDIT_URL}/v0.1/${gamePk}/${playGuid}/field_outline`;
    return this.axiosWrapper?.post(url, saveObj).then(response => response.data);
  };

  saveAudit = (saveObj, gamePk) => {
    const url = `${AUDIT_URL}/v0.1/${gamePk}/save_audit`;
    return this.axiosWrapper?.post(url, saveObj);
  };

  saveCalibration = (gamePk, playGuid, calibrationObj) => {
    const url = `${AUDIT_URL}/v0.1/${gamePk}/${playGuid}/calibration`;
    return this.axiosWrapper?.post(url, calibrationObj);
  };

  autoSave = (gamePk, pitches) => {
    const url = SERVICES_URL + "/audit/autosave/" + gamePk;
    return this.axiosWrapper?.post(url, pitches);
  };

  restore = gamePk => {
    const url = SERVICES_URL + "/audit/restore?gamePk=" + gamePk;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  getProblemPitchInfo = gamePk => {
    const url = SERVICES_URL + "/problemPitch/summary?gamePk=" + gamePk;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  // Audit V2
  getAuditV2Data = (gamePk, category, feedType) => {
    return Promise.all([
      this.getAuditConfiguration(gamePk),
      this.getPitchListV2(gamePk),
      this.getPlayersByGamePk(gamePk),
      this.getVideos(gamePk, category, feedType)
    ]).then(response => response);
  };

  getPitchListV2 = gamePk => {
    const url = SERVICES_URL + "/audit/v2/" + gamePk;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  saveCenterfields = (gamePk, centerfields) => {
    const url = SERVICES_URL + "/audit/centerfield/" + gamePk;
    return this.axiosWrapper?.post(url, centerfields).then(response => response.data);
  };

  saveKeyframeTimes = (gamePk, pitches) => {
    const url = SERVICES_URL + "/audit/keyframe/" + gamePk;
    return this.axiosWrapper?.post(url, pitches).then(response => response.data);
  };

  //Scoresheet
  getScoresheet = (gamePk, umpireId) => {
    const url = SERVICES_URL + "/scoresheet/" + gamePk + "/" + umpireId;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  updateScoresheet = scoresheet => {
    const url = SERVICES_URL + "/scoresheet";
    return this.axiosWrapper?.post(url, scoresheet).then(response => response.data);
  };

  releaseScoresheet = (gamePk, umpireId) => {
    const url = SERVICES_URL + "/scoresheet/release/" + gamePk + "/" + umpireId;
    return this.axiosWrapper?.post(url, "").then(response => response.data);
  };

  unreleaseScoresheet = (gamePk, umpireId) => {
    const url = SERVICES_URL + "/scoresheet/unrelease/" + gamePk + "/" + umpireId;
    return this.axiosWrapper?.post(url, "").then(response => response.data);
  };

  //Reports
  getReports = () => {
    const url = SERVICES_URL + "/reports";
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  sendBatch = (id, recipients, dateRange, gamePks, useHawkeye) => {
    const body = {
      id: id,
      recipients: recipients,
      startDate: dateRange.start,
      endDate: dateRange.end,
      gamePks: gamePks,
      useHawkeye: useHawkeye
    };
    const url = SERVICES_URL + "/reports";
    return this.axiosWrapper?.post(url, body).then(response => response.data);
  };

  sendReport = (id, recipients, date, includeUnreleased) => {
    const body = { id: id, recipients: recipients, date: date, includeUnreleased: includeUnreleased };
    const url = SERVICES_URL + "/reports";
    return this.axiosWrapper?.post(url, body).then(response => response.data);
  };

  getGameSummaries = (date, includeUnreleased) => {
    const url = SERVICES_URL + "/gameSummary?gameDate=" + date + "&includeUnreleased=" + includeUnreleased;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  //users

  getUsers = () => {
    const url = SERVICES_URL + "/users";
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  //Game Viewer

  getGameViewerData = (gamePk, umpireId) => {
    return Promise.all([
      this.getGameViewerPitchList(gamePk, umpireId),
      this.getGameViewerUmpire(umpireId),
      this.getGameViewerGame(gamePk, umpireId),
      this.getPitchCoordinates(gamePk, umpireId),
      this.getCameraSettings(gamePk)
    ])
      .then(response => response)
      .catch(() => {});
  };

  getGameViewerPitchList = (gamePk, umpireId) => {
    const url = SERVICES_URL + "/pitches/gameViewer/" + gamePk + "/" + umpireId;
    return this.axiosWrapper?.get(url);
  };

  getGameViewerGame = (gamePk, umpireId) => {
    const url = SERVICES_URL + "/gameViewer/game/" + gamePk + "/" + umpireId;
    return this.axiosWrapper?.get(url);
  };

  getGameViewerPitch = (gamePk, umpireId, playId) => {
    const url = SERVICES_URL + "/pitches/gameViewer/" + gamePk + "/" + umpireId + "/" + playId;
    return this.axiosWrapper?.get(url).then(response => response.data.entity);
  };

  getPitchCoordinates = (gamePk, umpireId) => {
    const url = SERVICES_URL + "/pitches/coordinates/" + gamePk + "/" + umpireId;
    return this.axiosWrapper?.get(url);
  };

  getAbsFeedbackReasons = () => {
    const url = SERVICES_URL + "/abs/feedback/reasons";
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  saveAbsFeedback = feedback => {
    const url = SERVICES_URL + "/abs/feedback";
    return this.axiosWrapper?.put(url, feedback).then(response => response.data);
  };

  getCameraSettings = gamePk => {
    const url = SERVICES_URL + "/camera/" + gamePk;
    return this.axiosWrapper?.get(url);
  };

  getGameStatus = (gamePk, umpireId) => {
    const url = SERVICES_URL + "/gameViewer/gameStatus/" + gamePk + "/" + umpireId;
    return this.axiosWrapper?.get(url);
  };

  updateCallCorrectness = (gamePk, playId, callCorrectness) => {
    const url = SERVICES_URL + "/pitches/adjust/" + gamePk;
    let body = {
      playId: playId,
      callCorrectness: callCorrectness
    };
    return this.axiosWrapper?.post(url, body).then(response => response.data.entity);
  };

  checkStrikeZones = (gamePk, umpireId, pitches) => {
    const url = SERVICES_URL + `/gameViewer/strikeZones/check/${gamePk}/${umpireId}`;
    return this.axiosWrapper?.post(url, pitches).then(response => response.data.entity);
  };

  // bq
  getSzPredict = bqRequest => {
    const url = SERVICES_URL + "/bq/szPredict";
    return this.axiosWrapper?.post(url, bqRequest).then(response => response.data);
  };

  // pitches controller
  saveStrikeZones = pitches => {
    const url = SERVICES_URL + "/pitches/strikeZone";
    return this.axiosWrapper?.post(url, pitches).then(response => response.data.entity);
  };

  // pitchcast graphics
  backProject = (xyzCoordinates, calibration) => {
    const url = SERVICES_URL + "/graphics/back";
    let body = {
      xyzCoordinates: xyzCoordinates,
      calibrationData: calibration
    };
    return this.axiosWrapper?.post(url, body).then(response => response.data);
  };

  backProjectV2 = backProjectionRequest => {
    const url = SERVICES_URL + "/v2/graphics/backHeight";
    return this.axiosWrapper?.post(url, backProjectionRequest).then(response => response.data);
  };

  forwardCircle = (gamePk, playId, xyzCoordinates) => {
    const url = SERVICES_URL + "/graphics/circle";
    let body = {
      gamePk: gamePk,
      playId: playId,
      xyzCoordinates: xyzCoordinates
    };
    return this.axiosWrapper?.post(url, body).then(response => response.data);
  };

  forwardProject = (gamePk, playId, xyzCoordinates) => {
    const url = SERVICES_URL + "/graphics/forward";
    let body = {
      gamePk: gamePk,
      playId: playId,
      xyzCoordinates: xyzCoordinates
    };
    return this.axiosWrapper?.post(url, body).then(response => response.data);
  };

  forwardProjectV2 = (gamePk, playId, xyzCoordinates, smoothing, roll) => {
    const url = SERVICES_URL + "/v2/graphics/forward";
    let body = {
      gamePk: gamePk,
      playId: playId,
      xyzCoordinates: xyzCoordinates,
      smoothing: smoothing,
      roll: roll
    };
    return this.axiosWrapper?.post(url, body).then(response => response.data);
  };

  // people
  getGameViewerUmpire = umpireId => {
    const url = SERVICES_URL + "/person/umpire/" + umpireId;
    return this.axiosWrapper?.get(url);
  };

  getPlayersByGamePk = gamePk => {
    const url = SERVICES_URL + "/person/game/" + gamePk;
    return this.axiosWrapper?.get(url).then(response => response.data.entity);
  };

  // pitches
  getPitch = (gamePk, playId) => {
    const url = SERVICES_URL + "/pitches/" + gamePk + "/" + playId;
    return this.axiosWrapper?.get(url).then(response => response.data.entity);
  };

  // videos
  getVideos = (gamePk, category, feedType) => {
    let url = SERVICES_URL + "/video/" + gamePk + "/" + category;
    if (feedType) {
      url += "?feedType=" + feedType;
    }
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  // frames
  moveKeyframe = (gamePk, playId, keyframeOffset, feedType) => {
    let url = SERVICES_URL + "/clip/frame";
    const request = {
      plays: [
        {
          gamePk: gamePk,
          playId: playId,
          keyframeOffset: keyframeOffset,
          feedType: feedType
        }
      ],
      feedType: feedType
    };
    return this.axiosWrapper?.post(url, request).then(response => response.data);
  };

  // extract calibrations
  extractCalibrations = gamePk => {
    const url = SERVICES_URL + "/vcr/" + gamePk + "?overwrite=false";
    return this.axiosWrapper?.get(url).then(response => response.data);
  };

  saveCurrentPitchHiddenVideos = (gamePk, videos) => {
    const url = SERVICES_URL + "/video/" + gamePk + "/hiddenVideos";
    return this.axiosWrapper?.post(url, videos).then(response => response.data);
  };

  markPitch = (gamePk, playId, umpireId) => {
    const url = SERVICES_URL + "/pitches/gameViewer/" + gamePk + "/" + playId + "/" + umpireId + "/mark";
    return this.axiosWrapper?.post(url).then(response => response.data);
  };

  unmarkPitch = (gamePk, playId, umpireId) => {
    const url = SERVICES_URL + "/pitches/gameViewer/" + gamePk + "/" + playId + "/" + umpireId + "/unMark";
    return this.axiosWrapper?.post(url).then(response => response.data);
  };

  backupPCOffsets = (gamePk, transformedOffsets) => {
    const url = SERVICES_URL + "/audit/backup/" + gamePk;
    return this.axiosWrapper?.post(url, transformedOffsets).then(response => response.data);
  };

  restorePCOffsets = gamePk => {
    const url = SERVICES_URL + "/audit/restore/" + gamePk;
    return this.axiosWrapper?.get(url).then(response => response.data);
  };
}

export default ZeApi;
