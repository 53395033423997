import React from "react";
import { inject, observer } from "mobx-react";
import ZeLightButton from "../../elements/form/ZeLightButton";
import CancelModal from "../../common/action-modals/CancelModal";
import ModalHeaderWithTitleAndClose from "../../common/modal/ModalHeaderWithTitleAndClose";
import ModalBody from "../../common/modal/ModalBody";
import ModalFooter from "../../common/modal/ModalFooter";
import Modal from "../../common/modal/Modal";
import AuditQuestionableSzTable from "../../audit/common/AuditQuestionableSzTable";

class AuditPcFooterButtons extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  cancelButton() {
    const { auditPcStore } = this.props.rootStore;
    return (
      <ZeLightButton className="float-right" onClick={() => (auditPcStore.showCancelModal = true)}>
        Cancel
      </ZeLightButton>
    );
  }

  closeModal(event) {
    event.preventDefault();
    const { auditPcStore } = this.props.rootStore;
    auditPcStore.setShowConfirmationModal(false);
  }

  copyStrikeZonesButton() {
    const { functions } = this.props.rootStore.auditPcStore;
    return (
      <ZeLightButton className="float-right" onClick={functions.copyStrikeZonesToClipboard.bind(functions)}>
        Copy Strike Zones
      </ZeLightButton>
    );
  }

  defaultButton() {
    const { functions } = this.props.rootStore.auditPcStore;
    return (
      <ZeLightButton className="float-right" onClick={functions.defaultZone}>
        Default Zone
      </ZeLightButton>
    );
  }

  extractButton() {
    const { functions } = this.props.rootStore.auditPcStore;
    return (
      <ZeLightButton className="float-right" onClick={functions.extractCalibrations.bind(functions)}>
        Extract Calibrations
      </ZeLightButton>
    );
  }

  oobPitches() {
    const { oobPitches, players } = this.props.rootStore.auditPcStore;
    if (!oobPitches.length) {
      return null;
    }
    return (
      <div>
        <div>One or more pitches has a questionable strike zone.</div>
        <AuditQuestionableSzTable oobPitches={oobPitches} players={players} />
      </div>
    );
  }

  refreshButton() {
    const { functions } = this.props.rootStore.auditPcStore;
    return (
      <ZeLightButton className="float-right" onClick={functions.refreshPitch}>
        Refresh Pitch
      </ZeLightButton>
    );
  }

  saveAuditButton() {
    const { auditPcStore } = this.props.rootStore;
    const { dirtyPitches, functions, selectedPitch } = auditPcStore;
    return (
      <button
        className="btn btn-primary float-right mr-2"
        disabled={dirtyPitches.length === 0}
        onClick={() => {
          if (selectedPitch.dirty) {
            functions.saveCurrentPitch(selectedPitch).then(() => {
              auditPcStore.setLoading(false);
              auditPcStore.setShowConfirmationModal(true);
            });
          } else {
            auditPcStore.setShowConfirmationModal(true);
          }
        }}
      >
        Finalize Audit
      </button>
    );
  }

  saveCalOnlyButton() {
    const { dirtyPitches, functions } = this.props.rootStore.auditPcStore;
    return (
      <button
        className="btn btn-primary float-right mr-2"
        disabled={dirtyPitches.length === 0}
        onClick={() => {
          functions.saveCalOnly();
        }}
      >
        Finalize Calibrations
      </button>
    );
  }

  saveMessage() {
    const { auditPcStore } = this.props.rootStore;
    return (
      <div>
        You are saving strike zone changes for <b key="body">{auditPcStore.dirtyPitches.length}</b> pitches.
      </div>
    );
  }

  renderConfirmationModal() {
    const { auditPcStore } = this.props.rootStore;
    let oobPitchCount = auditPcStore.oobPitches.length;
    return (
      <Modal show={auditPcStore.showConfirmationModal} onClose={this.closeModal} size="lg">
        <ModalHeaderWithTitleAndClose title="Finalize Audit" close={this.closeModal} />

        <ModalBody>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <div className="row" />
                {this.saveMessage()}
                {this.oobPitches()}
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          {oobPitchCount ? (
            <button
              className="btn btn-primary"
              onClick={auditPcStore.functions.excludeAndSaveAuditPitches.bind(auditPcStore.functions)}
              disabled={!auditPcStore.dirtyPitches}
            >
              Exclude and Finalize
            </button>
          ) : null}
          <button
            className={oobPitchCount ? "btn btn-secondary" : "btn btn-primary"}
            onClick={auditPcStore.functions.saveAuditPitches.bind(auditPcStore.functions)}
            disabled={!auditPcStore.dirtyPitches}
          >
            Finalize All
          </button>
          <ZeLightButton onClick={this.closeModal}>Cancel</ZeLightButton>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { auditPcStore } = this.props.rootStore;
    return (
      <div className="row">
        <div className="col pr-1">
          {this.renderConfirmationModal()}
          <CancelModal
            show={auditPcStore.showCancelModal}
            closeFunc={() => (auditPcStore.showCancelModal = false)}
            cancelFunc={() => auditPcStore.cancelAudit()}
          />
          <div className="float-right">
            {this.cancelButton()}
            {this.extractButton()}
            {this.refreshButton()}
            {this.defaultButton()}
            {this.copyStrikeZonesButton()}
            {auditPcStore.auditEnabled &&
              (auditPcStore.strikeZoneEditable ? this.saveAuditButton() : this.saveCalOnlyButton())}
          </div>
        </div>
      </div>
    );
  }
}

export default inject("rootStore")(observer(AuditPcFooterButtons));
